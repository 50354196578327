import * as NumberUtils from '../../../utils/Number';

export const prepareReceiptData = (res, setItem, exclude_shipping = false, depositPercentage = null) => {
    const itemData = [];
    let total_price = 0;

    res.items.forEach(item => {
        const productCode = item.model_sku === '' ? item.item_sku : item.model_sku;
        const quantity_purchased = item.quantity_purchased;
        const original_price = item.original_price;
        const price_per_unit = Number(original_price) / Number(quantity_purchased);
        total_price += Number(original_price);
        itemData.push({
            col1: productCode,
            col2: item.item_name + ' ' + item.model_name,
            col3: NumberUtils.addCommars(quantity_purchased),
            col4: NumberUtils.addCommars(parseFloat(price_per_unit).toFixed(2)),
            col5: NumberUtils.addCommars(parseFloat(original_price).toFixed(2)),
        });
    });

    if (!exclude_shipping && parseFloat(res.buyer_paid_shipping_fee) > 0) {
        // Add shipping price
        itemData.push({
            col1: ' ',
            col2: 'ค่าจัดส่ง',
            col3: '1',
            col4: NumberUtils.addCommars(parseFloat(res.buyer_paid_shipping_fee).toFixed(2)),
            col5: NumberUtils.addCommars(parseFloat(res.buyer_paid_shipping_fee).toFixed(2)),
        });
    } else {
        res.buyer_paid_shipping_fee = 0;
    }
    if (parseFloat(res.seller_discount) > 0) {
        // Add total discount
        itemData.push({
            col1: ' ',
            col2: 'ส่วนลด',
            col3: ' ',
            col4: ' ',
            col5: NumberUtils.addCommars(parseFloat(res.seller_discount).toFixed(2))
        });
    }

    // Calculate final total
    const finalTotal = total_price + Number(res.buyer_paid_shipping_fee) - Number(res.seller_discount);

    // Add total price row ("รวม")
    itemData.push({
        col1: ' ',
        col2: 'รวม',
        col3: ' ',
        col4: ' ',
        col5: NumberUtils.addCommars(parseFloat(finalTotal).toFixed(2))
    });

    // If a deposit percentage is provided (and >0), add a deposit row underneath the total
    if (depositPercentage && parseFloat(depositPercentage) > 0) {
        const depositAmount = finalTotal * (parseFloat(depositPercentage) / 100);
        itemData.push({
            col1: ' ',
            col2: `มัดจำ (${parseFloat(depositPercentage).toFixed(0)}%)`,
            col3: ' ',
            col4: ' ',
            col5: NumberUtils.addCommars(depositAmount.toFixed(2))
        });
    }

    setItem(itemData);
};
