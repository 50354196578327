import React, { useEffect, useMemo } from 'react';
import NavBar from '../../../../components/navBar/NavBar';
import './ReceiptDetail.scss';
import * as ReceiptAPI from '../../../../utils/API/receipt-api';
import { redirect } from '../../../../globalMethods';
import useState from 'react-usestateref';
import { ReceiptHeader } from './TableColumns';
import { prepareReceiptData } from '../ReceiptDetailUtils';
import { useReactToPrint } from 'react-to-print';
import { ReceiptDetailToPrint } from '../../components/ToPrint';
import { CustomTable } from '../../../../components/CustomTable/CustomTableFunction';

export default function ReceiptDetail() {
    const parameters = window.location.pathname.split('/')[3];
    const orderSN = parameters.split('.')[0];
    let password = parameters.split('.')[1];
    const byPass = parameters.split('.')[1];
    const [receiptDetail, setReceiptDetail, receiptDetailRef] = useState(null);
    const [orderDetail, setOrderDetail] = useState(null);
    const [save_password, setSave_password] = useState(null);
    const receiptHeader = useMemo(() => ReceiptHeader, []);
    const [receiptData, setReceiptData] = useState([]);
    const receiptPrintRef = React.useRef(null);
    const [conditionNotMet, setConditionNotMet] = useState(false);
    const [orderNotReady, setOrderNotReady] = useState(false);
    const [signature, setSignature] = useState(false);
    const [doc_type, setDoc_type] = useState('e-Receipt');
    const [is_quotation, setIs_quotation] = useState(true);
    const [exclude_shipping, setExclude_shipping] = useState(false);
    const [alert_text, setAlert_text] = useState('');
    const [is_submited, setIs_submited] = useState(false);

    // New state for deposit percentage hidden feature (for status 1 / 1.5)
    const [depositPercentage, setDepositPercentage] = useState('');
    const [showDepositInput, setShowDepositInput] = useState(false);

    const printReceipt = useReactToPrint({
        content: () => receiptPrintRef.current,
    });

    // Determine if the device is desktop (not mobile)
    const isDesktop = useMemo(() => {
        if (typeof navigator !== 'undefined') {
            // Check common mobile user agents
            return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
        return true;
    }, []);

    // Handler for printing: allow only on desktop devices.
    const handlePrint = () => {
        if (isDesktop) {
            printReceipt();
        } else {
            alert('Printing is available only on computer devices.');
        }
    };

    // When either the exclude_shipping or depositPercentage changes and orderDetail is ready,
    // re-generate the table data including the deposit row if applicable.
    useEffect(() => {
        if (orderDetail) {
            prepareReceiptData(orderDetail, setReceiptData, exclude_shipping, depositPercentage);
        }
    }, [exclude_shipping, depositPercentage]);

    useEffect(() => {
        // Dialog asking for password
        if (password === undefined) {
            password = prompt('โปรดกรอกรหัส(หมายเลขโทรศัพท์ที่ใช้สำหรับคำสั่งซื้อนี้)');
        }
        if (password) {
            ReceiptAPI.getReceiptDetail(orderSN, password).then((res) => {
                const data = res.data;
                if (data.ReceiptAddress === null) {
                    data.ReceiptAddress = {};
                }
                const order_status = data.OrderStatus;
                if (order_status === 20) {
                    // Order cancelled
                    setConditionNotMet(true);
                    setAlert_text('ไม่สามารถออกใบเสร็จได้ สาเหตุ : Order ยกเลิก');
                }
                if (order_status === 1 || order_status === 1.5) {
                    setDoc_type('e-Quotation/Invoice');
                }
                setReceiptDetail(data);
                if (data.ReceiptAddressID !== null) {
                    console.log("Have receipt");
                    setIs_submited(true);
                }
                const orderID = data.OrderID;
                const orderStatus = data.OrderStatus;
                // <=4 Not Shipped, 20 Cancelled, 26 Returned
                if ((orderStatus <= 4 || orderStatus === 20 || orderStatus === 26) && byPass === undefined) {
                    setConditionNotMet(true);
                    setAlert_text('ไม่สามารถออกใบเสร็จได้ สาเหตุ : Order ยังไม่ถูกจัดส่ง หรือ ยกเลิก');
                }
                ReceiptAPI.getOrderPaymentDetail(orderID).then((res) => {
                    const data = res.data.response;
                    if (data === null) {
                        setOrderNotReady(true);
                        setReceiptData(null);
                        setOrderDetail(null);
                        setAlert_text('ไม่สามารถออกใบเสร็จได้ สาเหตุ : Order ยังไม่พร้อมสำหรับใบเสร็จ หากสั่งซื้อผ่านช่องทาง Lazada โปรดรอ 1-3 วันหลังจากได้รับสินค้า');
                    } else {
                        // Pass exclude_shipping and depositPercentage to include the deposit row if needed
                        prepareReceiptData(data, setReceiptData, exclude_shipping, depositPercentage);
                        setOrderDetail(data);
                        setSave_password(password);
                    }
                });

            }).catch((err) => {
                alert(err.response.data.message);
                redirect('/receipt');
            });
        } else {
            alert('ยกเลิก');
            redirect('/receipt');
        }
    }, []);

    const SubmitUpdateReceiptDetail = () => {
        ReceiptAPI.updateReceiptDetail(orderSN, save_password, receiptDetailRef.current).then((res) => {
            alert('อัพเดทข้อมูลสำเร็จ');
            setIs_submited(true);
        }).catch((err) => {
            alert(err.response.data.message);
        });
    };

    return (
        <div className='receipt_detail'>
            <NavBar name='Receipt' clickAble={true} />
            <div className='receipt_detail__content'>
                {(conditionNotMet || orderNotReady)
                    ? <>
                        <h2>{alert_text}</h2>
                    </>
                    : <>
                        {receiptDetail && <>
                            <h2>{doc_type}</h2>
                            <div className="header">
                                <div className="header-title">
                                    <div className="title bold text-underlined">
                                        ข้อมูล บริษัท/ร้านค้า/บุคคล ที่ทำการสั่งซื้อ
                                    </div>
                                </div>
                                <div className="btn-group">
                                    {is_submited === false ?
                                        <button className='btn'
                                            onClick={() => {
                                                // Ask to confirm
                                                if (window.confirm('ยืนยันการออกใบเสร็จ? จะไม่สามารถแก้ไขข้อมูลได้หลังจากออกใบเสร็จแล้ว')) {
                                                    SubmitUpdateReceiptDetail();
                                                }
                                            }}
                                        >ยืนยัน</button>
                                        :
                                        <button className='btn'
                                            onClick={handlePrint}
                                        >พิมพ์</button>
                                    }
                                </div>
                            </div>
                            <div className="info">
                                <div className="item">
                                    <div className="label bold">ชื่อ</div>
                                    <input type="text" className="input_text" value={receiptDetail.ReceiptAddress.Name}
                                        onChange={(e) => {
                                            receiptDetailRef.current.ReceiptAddress.Name = e.target.value;
                                        }} />
                                </div>
                                <div className="item">
                                    <div className="label bold">ที่อยู่</div>
                                    <input type="text" className="input_text" value={receiptDetail.ReceiptAddress.Address}
                                        onChange={(e) => {
                                            receiptDetailRef.current.ReceiptAddress.Address = e.target.value;
                                        }} />
                                </div>
                                <div className="item">
                                    <div className="label bold">เลขประจำตัวผู้เสียภาษี</div>
                                    <input type="text" className="input_text" value={receiptDetail.ReceiptAddress.TaxID}
                                        onChange={(e) => {
                                            receiptDetailRef.current.ReceiptAddress.TaxID = e.target.value;
                                        }} />
                                </div>
                                {(receiptDetail.OrderStatus !== 1 && receiptDetail.OrderStatus !== 1.5) &&
                                    <>
                                        <div className="item">
                                            <div className="label bold"
                                                onDoubleClick={() => { setSignature(true) }}
                                            >ไม่ลงวันที่</div>
                                            <div className="input_checkbox">
                                                <input type="checkbox" checked={receiptDetail.NoReceiptDate}
                                                    onChange={(e) => {
                                                        receiptDetailRef.current.NoReceiptDate = e.target.checked;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="label bold">ไม่รวมค่าจัดส่ง</div>
                                            <div className="input_checkbox">
                                                <input type="checkbox" checked={exclude_shipping}
                                                    onChange={(e) => {
                                                        setExclude_shipping(e.target.checked);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                {(receiptDetail.OrderStatus === 1 || receiptDetail.OrderStatus === 1.5) &&
                                    <div className="item">
                                        {/* Double-clicking toggles the hidden deposit input */}
                                        <div className="label bold"
                                            onDoubleClick={() => { 
                                                setShowDepositInput(prev => !prev)
                                                setSignature(true)
                                            }}
                                        >ชนิด</div>
                                        <div className="input_checkbox">
                                            <div className="checkbox-group">
                                                <input type="checkbox" checked={is_quotation}
                                                    onChange={(e) => {
                                                        setIs_quotation(e.target.checked);
                                                    }}
                                                />
                                                <div className="label bold">
                                                    ใบเสนอราคา<br />Quotation
                                                </div>
                                            </div>
                                            <div className="checkbox-group">
                                                <input type="checkbox"
                                                    onChange={(e) => {
                                                        setIs_quotation(!e.target.checked);
                                                    }}
                                                    checked={!is_quotation}
                                                />
                                                <div className="label bold">
                                                    ใบแจ้งหนี้<br />Invoice
                                                </div>
                                            </div>
                                        </div>
                                        {/* Hidden deposit percentage input */}
                                        {showDepositInput && (
                                            <div className="item deposit-input">
                                                <div className="label bold">Deposit Percentage (%)</div>
                                                <input
                                                    type="number"
                                                    className="input_text"
                                                    value={depositPercentage}
                                                    onChange={(e) => setDepositPercentage(e.target.value)}
                                                    placeholder="Enter deposit percentage"
                                                />
                                            </div>
                                        )}
                                    </div>
                                }
                                <div className="label bold">
                                    ต้องทำการยืนยันก่อน ถึงจะสามารถ Download {doc_type} ได้
                                </div>
                                {
                                    !isDesktop &&
                                    // Red warning message for mobile users
                                    <div className="label bold"
                                        style={{ color: 'red', textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}
                                    >
                                        ไม่รองรับการพิมพ์จากบนมือถือ โปรดใช้คอมพิวเตอร์
                                    </div>

                                }
                            </div>
                        </>}
                        {orderDetail && <div className='order-detail'>
                            <h2>Order Detail</h2>
                            <div
                                style={{
                                    overflowX: 'auto',
                                }}
                            >
                                <CustomTable columns={receiptHeader} data={receiptData} />
                            </div>
                        </div>}
                        {receiptData &&
                            <div className='hide'
                                style={{
                                    overflowX: 'auto',
                                }}

                            >
                                {receiptDetail &&
                                    <ReceiptDetailToPrint
                                        ref={receiptPrintRef}
                                        tableHeader={receiptHeader}
                                        receiptDetail={receiptDetail}
                                        orderDetail={orderDetail}
                                        tableData={receiptData}
                                        CreateTime={receiptDetail.OrderCreateTime}
                                        orderSN={orderSN}
                                        original={true}
                                        signature={signature}
                                        is_quotation={is_quotation}
                                    />
                                }
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
}
